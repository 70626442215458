<template>
<div class="row tgju-widgets-row">
    <slot v-if="widgetLoad">
        <div class="text-center p-2 w100-w">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <div class="market-profile-inner">
            <div class="HighCharts-inner">
                <HighCharts chartHeight='250' :options="chart_block_0_options" v-if="chart_block_0_options"></HighCharts>
            </div>
            <ul>
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">خصیصه</div>
                        <div class="profile-inner-table-value">مقدار</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">آخرین بروز رسانی</div>
                        <div class="profile-inner-table-value">{{ market_profile.info.time }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.price.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.price.value"></div>
                    </div>
                </li>
                <li v-if="market_profile.summary.irr_price">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.irr_price.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.irr_price.value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.high.title }}</div>
                        <div class="profile-inner-table-value"><span v-html="market_profile.summary.high.value"></span></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.low.title }}</div>
                        <div class="profile-inner-table-value"><span v-html="market_profile.summary.low.value"></span></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.day_tolerance_d.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.day_tolerance_d.value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.day_tolerance_dp.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.day_tolerance_dp.value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.open.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.open.value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.time.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.time.value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.yesterday_price.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.yesterday_price.value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.yesterday_dp.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.yesterday_dp.value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">{{ market_profile.summary.yesterday_d.title }}</div>
                        <div class="profile-inner-table-value" v-html="market_profile.summary.yesterday_d.value"></div>
                    </div>
                </li>

            </ul>
        </div>
        
  </slot>

</div>
</template>

<style lang="scss" scoped>
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.high.dir,
.low.dir {
    max-height: 25px;
    margin: 10px;
}
</style>

<script>
// این کامپوننت دارای اجزای بخش صفحه اول نمایش پروفایل شاخص در بازار ها می باشد
import moment from "moment-jalaali";
import $ from "jquery";

// این کامپوننت دارای اجزای ویجت نمودار ها در ویجت ساز داشبورد می باشد .
import HighCharts from '@/plugins/HighCharts.vue';

export default {
    name: "MarketProfileComponent",
    components: {
        HighCharts,
    },
    data: function () {
        return {
            widgetLoad: true,
            market_profile: [],
            invest_items: [],
            content_market: [],
            special_news: [],
            category_performance: [],
            chart_block_0_options: null,
            chart_block_1_options: null,
            chart_block_3_options: null,
            invest_select: 72,
            invest_others_input: 0,
            flickityOptions: {
                autoPlay: true,
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true
            }
        };
    },
    mounted() {
        this.getProfileInformation();
    },
    methods: {
        // این متد برای نمایش زمان به صورت رلتیو می باشد
        time() {
            return moment().format("LTS");
        },
        // نمایش زمان بصورت 1401/03/12
        to_yyyy_mm_dd(date) {
            return moment(date).format("jYYYY/jMM/jDD");
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).format("dddd jD jMMMM jYYYY / HH:mm");
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        onChange() {
            this.calculateInvest();
        },
        someHandler(event) {
            this.invest_others_input = event.target.value;
            this.calculateInvest();
        },
        calculateInvest() {
            var base_price = this.invest_others_input;

            var key = this.invest_select
            var main_data = JSON.parse(JSON.stringify(this.market_profile.invest[this.$route.params.name]));

            var main_value;
            if ( base_price !== '' ) {
                main_value = main_data[key + '_base']['value'] * base_price;
            } else {
                main_value = main_data[key]['value'];
            }
            for ( var item_key in this.invest_items ) {
                var this_item = this.market_profile.invest[item_key];
                var this_elm = $('.invest-item[data-key="'+item_key+'"]');
                var this_data;
                var this_value;
                if ( base_price !== '' ) {
                    this_data = this_item[key+'_base'];
                    this_value = this_data['value'];
                    this_value = this_value * base_price;
                } else {
                    this_data = this_item[key];
                    this_value = this_data['value'];
                }

                this_elm.find('.padding-set-num').html(this.formatPrice(this_value) + ' <span class="currency-type">' + this_data['currency'] + '</span>');
            }
        },
        // این متد وظیفه دریافت اطلاعات پروفایل شاخص را دارد
        getProfileInformation() {
            this.$helpers
                .makeRequest("GET", `/market/profile-summary/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_profile = api_response.data.response.detail.response;
                        this.content_market = api_response.data.response.detail.content_market.response;    
                        this.invest_items = api_response.data.response.detail.invest_items;
                        this.special_news = api_response.data.response.detail.special_news.response;
                        this.$parent.title = this.market_profile.info.title + ' - ' + this.$route.meta.title;
                        this.category_performance = this.market_profile.category_performance[this.$route.params.name];
                        this.widgetLoad = false;
                        this.chart_block_0_options = {
                            chartData: JSON.parse(this.market_profile.today_chart),
                            tooltipTitle: 'قیمت امروز',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            yAxisLineColor: "#feeeec",
                            backgroundColor: false,
                            plotColor: "#892731",
                            markerFillColor: "#feeeec",
                            plotShadow: false,
                            tooltipOnlyTime: true,
                            navigatorEnabled: false
                        };

                        this.chart_block_1_options = {
                            chartData: JSON.parse(this.market_profile.today_chart_candle),
                            chartType: "candlestick",
                            chartTitle: "",
                            yAxisTitle: "",
                            buttonEnabled: false,
                            tooltipOnlyTime: true,
                            navigatorEnabled: true,
                            plotColor: "#cb494d",
                            upColor: "#56ac53"
                        };

                        this.chart_block_3_options = {
                            chartData: JSON.parse(this.market_profile.summary_chart),
                            tooltipTitle: 'قیمت',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            backgroundColor: false,
                            plotColor: "#54a7da",
                            markerFillColor: "#a9c0ce",
                            plotShadow: false,
                            navigatorEnabled: true
                        };
                    }
                });
        },
        // اضافه کردن تغییر وضعیت نمایش
        toggleMe(event) {
            $(event.target).parent().toggleClass('active');
        },
    },
};
</script>

<style >
@import 'https://static.tgju.org/components/tgju-fix/fix-custom.css?ver=09';
</style>
