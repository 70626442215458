<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12" style="padding: 0 !important;">
        <MarketHeader :data="$route.params.name"></MarketHeader>
        <!-- <div class="market-main-sub-tabs w-scroll-style">
            <div v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" :class="'market-main-sub-tab-item ' + (item.name == tabName ? 'active' : '') ">
                <router-link :to="{name: item.name, params: { name: $route.params.name }, }">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div> -->
    </div>
    <MarketOverview></MarketOverview>
</div>
</template>

<style>
    body.profile .profile-indicator .table-mobile-grid table.table tbody tr {
        border-bottom: none;
    }
    
    .high.no-arrow:before,
    .low.no-arrow:before {
        display: none !important;
    }

    .invest-item .table>thead>tr>th {
        padding: 14px 15px 13px;
    }

    div.invest-item[data-key] .table-padding-lg tr td.padding-set-num {
        padding: 18px 15px 19px 15px !important;
    }

    .widgets .tgju-widget-forms .select2-container .select2-selection--single {
        height: 34px;
    }
   
    .invest-block .tgju-widget-content {
        padding: 15px;
        font-weight: 500;
        color: #333 !important;
        line-height: 2;
        font-size: 13px;
        letter-spacing: 0 !important;
    }
    
    .select2-container--default {
        width: 100% !important;
    }
    .advertise {
        padding: 0px 5px;
        width: 100% !important;
    }
    .advertise.img-w100 img{
        width: 100% !important;
    }
    .hide-desktop-760{
        display:none !important;
    } 
   @media (max-width: 1220px) {
        .advertise {
            padding: 0px 5px;
            margin-top: 0 !important;
        }
        .profile-indicator .tgju-widget .table-mobile-grid tbody tr td:nth-child(1)::before {
            content: 'اندیکاتور :' !important;
        }

        .profile-indicator .tgju-widget  .table-mobile-grid tbody tr td:nth-child(2)::before {
            content: 'دوره زمانی اعمال شده' !important;
        }

        .profile-indicator .tgju-widget  .table-mobile-grid tbody tr td:nth-child(3)::before {
            content: 'مقدار' !important;
        }
        
        .profile-indicator-resistance .tgju-widget .table-mobile-grid tbody tr td:nth-child(1)::before {
            content: 'سطح : ' !important;
        }	

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(2)::before{
            content: 'کلاسیک (Classic)' !important;
        }

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(3)::before{
            content: 'فیبوناچی (Fibonacci)' !important;
        }

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(4)::before{
            content: 'کاماریلا (Camarilla)' !important;
        }

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(5)::before{
            content: 'وودی (Woodie)' !important;
        }

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(6)::before{
            content: 'دی مارک (DM)' !important;
        }
        .profile-performance-box .table > tbody > tr > td, .stocks-profile .table > tfoot > tr > td {
            padding: 10px 0px 9px !important;
            font-size: 11px !important;
        }
    }

    @media (max-width: 760px) {
        .hide-desktop-760{
            display:block !important;
        }
        /* body.profile  .tgju-widgets-row .tgju-widgets-block {
            padding: 0 7px !important;
        } */
        .widgets .tgju-widgets-row .tgju-widgets-block {
            margin-bottom: 10px;
        }
    }

    @media (max-width: 420px) {
        html .widgets .tgju-widget .table-padding-lg tr td {
            padding: 10px 20px !important;
            font-size: 12px !important;
        }
        .tgju-widgets-row {
            padding: 0;
        }
        .widgets .tgju-widgets-row .tgju-widgets-block {
            margin-bottom: 10px;
            /* padding: 0 7px !important; */
        }
        .widgets .tgju-widgets-row .tgju-widgets-block.tgju-widgets-block-comments {
            padding: 0 !important;
        }
        .tgju-widget .table-mobile-grid tbody {
            padding: 0px 15px !important;
        }
        .stocks-header {
            margin-bottom: 10px;
        }
        .widgets .tgju-widget .tgju-widget-content {
            padding: 6.4px 10px 20px 10px;
        }
    }

</style>



<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش اولیه مورد استفاده قرار میگیرد
import MarketOverview from "@/components/Mobile/Market/Profile/Overview";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Mobile/Market/Profile/Header';

export default {
    name: "marketSummary",
    components: {
        MarketHeader,
        MarketOverview,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
            tabName:'MarketProfile',
        };
    },
    mounted() {
    },
};
</script>